import React, { useState } from 'react'
//import { DateRangePicker } from 'react-date-range'

import NetlifyFormWrapper, { submitNetlifyForm } from '../NetlifyFormWrapper'
import Button from '../Button'

import useInputValue from '../../hooks/useInputValue'

// import 'react-date-range/dist/styles.css'
// import 'react-date-range/dist/theme/default.css'

const ApartmentReservation = ({ title }: Props) => {
  const [isSuccess, setIsSuccess] = useState(false)
  const [error, setError] = useState(null)
  const firstName = useInputValue()
  const lastName = useInputValue()
  const email = useInputValue()
  const phone = useInputValue()
  const message = useInputValue()

  // const [startDate, setStartDate] = useState(null)
  // const [endDate, setEndDate] = useState(null)

  // const handleDateChange = ranges => {
  //   console.log({ ranges })
  // }

  const formName = `Reservierung Wohnung ${title}`

  const onReservationRequest = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setIsSuccess(false)
    setError(null)

    const formData = {
      firstName: firstName.value,
      lastName: lastName.value,
      email: email.value,
      phone: phone.value,
      message: message.value,
    }

    try {
      await submitNetlifyForm(formData, formName)
      setIsSuccess(true)
    } catch (err) {
      console.error(err)
      setError('Etwas ist schiefgelaufen...')
    }
  }

  return (
    <div>
      <h2 className="text-2xl">Reservierung</h2>
      <hr className="my-2" />
      {isSuccess ? (
        <div
          className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative"
          role="alert"
        >
          <strong className="font-bold pr-2">Vielen Dank!</strong>
          <span className="block sm:inline">
            Wir werden uns schnellstmöglich bei Ihnen melden!
          </span>
          <button onClick={() => setIsSuccess(false)}>
            <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
              <svg
                className="fill-current h-6 w-6 text-black-500"
                role="button"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <title>Close</title>
                <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
              </svg>
            </span>
          </button>
        </div>
      ) : (
        <NetlifyFormWrapper
          formName={formName}
          onSubmit={onReservationRequest}
          className="w-full max-w-lg bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
        >
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="firstName"
            >
              Vorname
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="text"
              name="firstName"
              {...firstName}
              required
            />
          </div>

          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="lastName"
            >
              Nachname
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="text"
              name="lastName"
              {...lastName}
              required
            />
          </div>

          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="email"
            >
              Email
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="email"
              name="email"
              {...email}
              required
            />
          </div>

          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="phone"
            >
              Telefonnummer
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="tel"
              name="phone"
              {...phone}
              required
            />
          </div>

          {/* <div>
          <DateRangePicker
            ranges={[
              {
                startDate,
                endDate,
                key: 'selection',
              },
            ]}
            onChange={handleDateChange}
          />
        </div> */}

          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="message"
            >
              Nachricht
            </label>
            <textarea
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              name="message"
              rows={4}
              {...message}
              required
            />
          </div>
          {error && (
            <div
              className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 mb-5 rounded relative"
              role="alert"
            >
              <strong className="font-bold">{error}</strong>
              <button onClick={() => setError(null)}>
                <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                  <svg
                    className="fill-current h-6 w-6 text-black-500"
                    role="button"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <title>Close</title>
                    <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
                  </svg>
                </span>
              </button>
            </div>
          )}
          <Button type="submit">Anfrage versenden</Button>
        </NetlifyFormWrapper>
      )}
    </div>
  )
}

interface Props {
  title: string
}

export default ApartmentReservation
