interface Image {
  src: {
    publicURL: string
  }
  alt: string
}

export interface MarkdownRemarkApartment {
  id?: string
  html?: string
  fields: {
    slug: string
  }
  frontmatter: {
    title: string
    headerImage: {
      publicURL: string
    }
    metaDescription: string
    areaInSquareMeters: number
    numberOfBathRooms: number
    numberOfBeds: number
    images?: Image[]
  }
}

export interface Apartment {
  id?: string
  slug: string
  title: string
  headerImage: string
  metaDescription: string
  areaInSquareMeters: number
  numberOfBathRooms: number
  numberOfBeds: number
  images?: Image[]
  html?: string
}

const formatApartment = (apartment: MarkdownRemarkApartment) => {
  const {
    id,
    html = null,
    fields: { slug },
    frontmatter: {
      title,
      headerImage: { publicURL: headerImage },
      metaDescription,
      areaInSquareMeters = 0,
      numberOfBathRooms = 0,
      numberOfBeds = 0,
      images = [],
    },
  } = apartment

  const formattedApartment: Apartment = {
    id,
    slug,
    title,
    headerImage,
    metaDescription,
    areaInSquareMeters,
    numberOfBathRooms,
    numberOfBeds,
    images: images || [],
    html,
  }

  return formattedApartment
}

export default formatApartment
