import React from 'react'
import { graphql } from 'gatsby'

import SEO from '../components/SEO'
import HeroHeader from '../components/HeroHeader'
import { ApartmentSlider } from '../components/Slider'
import ApartmentQuickInfo from '../components/Apartment/ApartmentQuickInfo'
import ApartmentReservation from '../components/Apartment/ApartmentReservation'

import formatApartment, {
  MarkdownRemarkApartment,
} from '../utils/formatters/formatApartment'

const { GATSBY_ORIGIN } = process.env

export const ApartmentTemplate = ({
  title,
  metaDescription,
  html,
  headerImage,
  areaInSquareMeters,
  numberOfBathRooms,
  numberOfBeds,
  images = [],
}: ApartmentTemplateProps) => {
  return (
    <>
      <SEO
        title={`${title} Wohnung | Stadlwirt`}
        description={metaDescription}
        image={`${GATSBY_ORIGIN}${headerImage}`}
      />
      <HeroHeader title={title} staticBackgroundImage={headerImage} />
      <div className="container contentWrapper my-10">
        <div className="flex flex-wrap">
          <div className="w-full mb-5 lg:w-3/12 lg:mb-0">
            <ApartmentQuickInfo
              areaInSquareMeters={areaInSquareMeters}
              numberOfBathRooms={numberOfBathRooms}
              numberOfBeds={numberOfBeds}
            />
          </div>
          <div className="w-full lg:w-8/12 lg:pl-5">
            <div dangerouslySetInnerHTML={{ __html: html }} />
          </div>
        </div>

        {images.length > 0 && (
          <div className="px-5 py-10 mb-5">
            <ApartmentSlider>
              {images.map((image) => {
                const {
                  src: { publicURL: src },
                  alt,
                } = image
                return (
                  <div key={src} className="px-2">
                    <img src={src} alt={alt} />
                  </div>
                )
              })}
            </ApartmentSlider>
          </div>
        )}

        <ApartmentReservation title={title} />
      </div>
    </>
  )
}

interface ApartmentTemplateProps {
  title: string
  metaDescription: string
  html: string
  headerImage: string
  areaInSquareMeters: number
  numberOfBathRooms: number
  numberOfBeds: number
  images: {
    src: {
      publicURL: string
    }
    alt: string
  }[]
}

const Apartment = ({ data }: ApartmentProps) => {
  const apartment = formatApartment(data.markdownRemark)

  const {
    html,
    title,
    metaDescription,
    headerImage,
    areaInSquareMeters,
    numberOfBathRooms,
    numberOfBeds,
    images = [],
  } = apartment

  return (
    <ApartmentTemplate
      title={title}
      metaDescription={metaDescription}
      headerImage={headerImage}
      areaInSquareMeters={areaInSquareMeters}
      numberOfBathRooms={numberOfBathRooms}
      numberOfBeds={numberOfBeds}
      images={images}
      html={html}
    />
  )
}

interface ApartmentProps {
  data: {
    markdownRemark: MarkdownRemarkApartment
  }
}

export default Apartment

export const pageQuery = graphql`
  query ApartmentByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      fields {
        slug
      }
      frontmatter {
        title
        headerImage {
          publicURL
        }
        metaDescription
        areaInSquareMeters
        numberOfBathRooms
        numberOfBeds
        images {
          src {
            publicURL
          }
          alt
        }
      }
    }
  }
`
