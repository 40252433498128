import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faSquare,
  //faUser,
  faShower,
  faBed,
} from '@fortawesome/free-solid-svg-icons'

// shower
// bed
// bed

const ApartmentQuickInfo = ({
  areaInSquareMeters,
  numberOfBathRooms,
  numberOfBeds,
}: Props) => {
  return (
    <div className="bg-white border p-6 rounded-lg overflow-hidden h-full">
      <h2 className="text-xl">Übersicht</h2>
      <div className="text-gray-700">
        <table>
          <tbody>
            <tr>
              <td>
                <FontAwesomeIcon icon={faSquare} />
              </td>
              <td className="ml-2">{areaInSquareMeters} m²</td>
            </tr>
            {/* <tr>
              <td>
                <FontAwesomeIcon icon={faUser} />
              </td>
              <td className="ml-2"> X Personen</td>
            </tr> */}
            <tr>
              <td>
                <FontAwesomeIcon icon={faShower} />
              </td>
              <td className="ml-2">{numberOfBathRooms} Bäder</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon icon={faBed} />
              </td>
              <td className="ml-2">{numberOfBeds} Betten</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

interface Props {
  areaInSquareMeters: number
  numberOfBathRooms: number
  numberOfBeds: number
}

export default ApartmentQuickInfo
